<template>
    <div class="outside">
        <div class="potential">
            <rxNavBar title="合同" v-if="fromType == '0'"></rxNavBar>
            <rxNavBar title="合同" androidOrIOSFlag="true" v-else></rxNavBar>
        </div>
        <!-- 顶部固定区域-->
        <div class="header">
            <!-- 合同切换区域-->
            <div class="choose">
                <div class="choose-one">
                    <div :class="type=='1'?'choose-one-textActive':'choose-one-text'" @click="showTenant">租客合同</div>
                    <div class="choose-one-icon" v-show="type=='1'"></div>
                </div>
                <div class="choose-line"></div>
                <div class="choose-one">
                    <div :class="type!='1'?'choose-one-textActive':'choose-one-text'" @click="showOwner">业主合同</div>
                    <div class="choose-one-icon" v-show="type!='1'"></div>
                </div>
            </div>
            <!-- 搜索区域和类型区域-->
            <div class="searchAndType">
                <!-- 搜索区域-->
                <div class="search">
                    <div class="search-icon"></div>
                    <input type="text" placeholder="合同编号/房源地址/姓名、手机号" v-model="searchName" @keyup.enter="searchSubmit">
                </div>
                <!-- 类型区域-->
                <div class="types">
                    <span class="type" v-if="typeList[number]" @click="isShow = !isShow" id="downList">{{typeList[number].dictionaryTitle}}</span>
                    <div :class="isShow?'small-icon-rotate':'small-icon'"></div>
                    <span :class="isChooseLeaseChange=='1'?'leaseChangeActive':'leaseChange'" @click="chooseOnLease">租约变更</span>
                </div>
                <!--下拉列表-->
                <!--                <div class="selectList" v-show="isShow">-->
                <!--                    <div class="select" :class="{selectActive:index==number}" @click="changeType(index)" v-for="(item,index) in typeList" :key="index">-->
                <!--                        {{item.dictionaryTitle}}-->
                <!--                    </div>-->
                <!--                </div>-->
            </div>
        </div>
        <!--合同列表区域-->
        <div class="blankWeight"></div>
        <div class="contracts">
            <van-pull-refresh v-model="refreshing"  @refresh="onRefresh">
                <van-list
                        v-model="loading"
                        :finished="finished"
                        :finished-text="noContent ? '' : '已经到底了'"
                        @load="this.onLoad"
                >
                    <!--合同列表-->
                    <div class="contract" v-for="(item,index) in contractList" :key="index">
                        <div :class="item.contractStatusVal=='租期中'?'top-title-green':item.contractStatusVal=='已解除'?'top-title-red':'top-title-orange'">{{item.contractStatusVal}}</div>
                        <div class="position">{{item.houseAddress}}</div>
                        <div class="tel-icon" @click="relationTo(item)" v-if="(type=='1'&&checkAuthList(authRenterButtonsList,'findPhone')) ||(type=='2'&&checkAuthList(authOwnerButtonsList,'findPhone'))"></div>
                        <div class="details">
                            <div class="info">
                                <div class="order">租金</div>
                                <div class="order-name">{{item.rentMoney}}元/月</div>
                            </div>
                            <div class="line"></div>
                            <div class="info">
                                <div class="order">支付</div>
                                <div class="order-name">{{item.payTypeName}}</div>
                            </div>
                            <div class="line"></div>
                            <div class="info">
                                <div class="order">{{type=='1'?'租客':'业主'}}</div>
                                <div class="order-name">{{type=='1'?item.renterName:item.ownerName}}</div>
                            </div>
                            <div class="line"></div>
                            <div class="info">
                                <div class="order">审核状态</div>
                                <div class="order-name">{{(!item.isUserComfirmProperty || item.isUserComfirmProperty=='0') && item.contractHistoryHistoryTypeVal!='续签'?'未交割':(item.approvalStatusVal?item.approvalStatusVal:'未审批')}}</div>
                            </div>
                        </div>
                        <div class="contract-info" @click="detailsClick(index)">
                            <div class="contract-text">合同编号：{{item.contractCode}}</div>
                            <div class="contract-text">签约时间：{{item.signTime}}</div>
                            <div class="contract-text">租期：{{item.contractBeginTime}} 至 {{item.contractEndTime}}</div>
                            <div class="contract-text" v-if="item.fangxinunionId&&item.fangxinunionId!=''&&item.fangxinAuthentication==0">该用户未进行实名认证操作</div>
                        </div>
                        <!--待签字-->
                        <div class="bottom" v-if="item.contractStatusVal=='待签字'">
<!--                          <div @click="checkFangxinAuthentication(item)" class="bottom-sign" v-if="item.fangxinunionId&&item.fangxinunionId!=''&&item.fangxinAuthentication==0&&item.renterCertificateType=='0'">人脸认证</div>-->
<!--                          <div @click="signature(item)" class="bottom-sign" v-if="(type=='1'&&checkAuthList(authRenterButtonsList,'signContract')&&!(item.fangxinunionId&&item.fangxinunionId!=''&&item.fangxinAuthentication==0&&item.renterCertificateType=='0')) ||(type=='2'&&checkAuthList(authOwnerButtonsList,'sign'))">签字</div>-->
                          <div @click="signature(item)" class="bottom-sign" v-if="(type=='1'&&checkAuthList(authRenterButtonsList,'signContract')) ||(type=='2'&&checkAuthList(authOwnerButtonsList,'sign'))">签字</div>
                            <div @click="changeContract(item)" class="bottom-change" v-if="(type=='1'&&checkAuthList(authRenterButtonsList,'edit')) ||(type=='2'&&checkAuthList(authOwnerButtonsList,'editContract'))">修改</div>
                            <div @click="invalidContract(item)" class="bottom-invalid" v-if="(type=='1'&&checkAuthList(authRenterButtonsList,'toVoid')) ||(type=='2'&&checkAuthList(authOwnerButtonsList,'toVoid'))">作废</div>

                        </div>
                        <!--租期中-->
                        <div class="bottom" v-if="item.contractStatusVal=='租期中'">
                
                            <div class="bottom_iconDiv" v-if="item.isEquipment&&isChooseLeaseChange == '1'">

                                <div class="icon" :class="item.number != 3 ? 'trashCanHide':''" @click="getPassword(item)">
                                   <img class="pwd_IMG"  src="../../../assets/images/RepairPending-getPwd.png">
                                   <span class="getPwd">获取密码</span>
                                </div>
                             </div>
                                            
        <van-overlay :show="pwdShow" @click="show = false">
            <div class="wrapper" @click.stop >
                <div class="block" >
                    <!--  获取密码的div-->
                    <div class="getPassword">
                        获取密码
                    </div>
                    <!--  门锁密码展示的DIV-->
                    <div class="LockPassWord">
                        <!--<p class="password">{{doorPassWord}}</p>-->
                        <p class="password">内门钥匙:{{districtPassWord}}</p>
                        <p class="password">大门钥匙:{{doorPassWord}}</p>                        
                    </div>
                </div>
                <div class="closeImg" @click="closeMobileModel">
                </div>
            </div>
        </van-overlay>
                          <div @click="changeContract(item)" class="bottom-change" v-if="(type=='1'&&checkAuthList(authRenterButtonsList,'edit')&&item.approvalStatus=='2')">修改</div>
                          <div @click="changeLease(item)" class="bottom-changeLease"
                                 v-if="(!item.contracthistoryId)&&item.setHouse_id&&item.approvalStatus&&item.approvalStatus=='1'
                                 &&checkCanLease()">租约变更</div>
                            <div @click="confirm(item)" class="bottom-property"
                                 v-if="((item.approvalStatusVal=='驳回'||item.approvalStatusVal=='未审批')&&isChooseLeaseChange == '0')&&(item.contractHistoryHistoryType=='1'||((!item.isUserComfirmProperty || item.isUserComfirmProperty=='0')&&
                                    (!item.contracthistoryId || (item.contracthistoryId && item.contractHistoryStatus != '3'&&item.contractHistoryHistoryType!=1))))">
                                {{item.contractPropertyId?'修改入住交割单':'添加入住交割单'}}
                            </div>
                            <div @click="confirmT(item)" class="bottom-property"
                                 v-if="(item.approvalStatusVal=='已审批'&&isChooseLeaseChange == '1')&&((!item.isUserComfirmProperty || item.isUserComfirmProperty=='0')&&
                                    (!item.contracthistoryId || (item.contracthistoryId && item.contractHistoryStatus != '3'&&item.contractHistoryHistoryType!=1)))&&item.matainStaff_id==matainStaff_id">
                                {{item.contractPropertyId?'修改解除交割单':'添加解除交割单'}}
                            </div>
                            <div @click="goCancel(item)" class="bottom-property"
                                 v-if="cancelLimits&&(item.contractHistoryStatus==0||item.contractHistoryStatus==5||item.contractHistoryStatus==6||item.contractHistoryStatus==7)">取消</div>
                            <div @click="whetherReject(item)" class="bottom-invalid" v-if="(type=='1'&&checkAuthList(authRenterButtonsList,'cancellationOfContract')&&(item.approvalStatusVal=='驳回'||item.approvalStatusVal=='未审批')&&isChooseLeaseChange==='0')">管理作废</div>

                        </div>
                        <!--已解除和已作废-->
                        <div class="bottom" v-if="item.contractStatusVal=='已解除' || item.contractStatusVal=='已作废'">
                        </div>
                    </div>
                    <common-empty v-if="noContent"></common-empty>
                </van-list>
            </van-pull-refresh>
            <!--拨打电话弹窗-->
            <dial-mobile :ownerMobile="temporaryOwnerMobile" :userName="temporaryOwnerName"
                         :dialogTitle="dialogTitle" :mobileShow="isMobileShow" @hideDialog="hideDialog"></dial-mobile>
        </div>
        <van-popup v-model="isShow" position="bottom">
            <van-picker
                    show-toolbar
                    :columns="typeList"
                    @cancel="isShow = false"
                    :default-index="number"
                    value-key="dictionaryTitle"
                    @confirm="changeType"
            />
        </van-popup>
        <!--      管理作废-->
        <van-popup class="van-popup--bottom" v-model="cancellationOfContractShow" position="bottom"
                   style="backgroundColor:#ffffff">
            <div>
              <img class="cancel-img" src="../../../assets/images/quotationmarks.png">
              <span class="weight">确认</span><br>
              <span class="weight" style="margin-left: 38px">管理作废该合同？</span>
            </div>
            <div class="part-inputpart">
              <!--作废原因-->
              <div ref="chargePseson" class="part-inputpart-row" @click="clickCancelReasonShow">
                <span :class="cancellationOfContract.reasonForCancellationStr? 'content-have' : 'content-none'"></span>
                <span class="part-inputpart-row-header">作废原因</span>
                <span class="content-divide">|</span>
                <span class="part-inputpart-row-graytext"
                      :class="cancellationOfContract.reasonForCancellationStr ? 'part-inputpart-row-normaltext':'' ">{{ cancellationOfContract.reasonForCancellationStr }}</span>
                <img class="part-inputpart-row-right"
                     :class=" isCancelReasonSelect ? 'part-inputpart-row-right-upArrow' : 'part-inputpart-row-right-downArrow' "
                     src="../../../assets/images/triangle.png">
              </div>
              <!--作废原因选项-->
              <van-popup v-model="isCancelReasonSelect" position="bottom">
                <van-picker
                    show-toolbar
                    :columns="cancelReasonList"
                    @cancel="isCancelReasonSelect = false"
                    :default-index="cancelReasonIndex"
                    value-key="dictionaryTitle"
                    @confirm="selectCancelReasonOption"
                />
              </van-popup>
            </div>
            <textarea class="cancel-textarea" v-model="cancellationOfContract.nullifyReason" placeholder="输入取消原因"></textarea>
            <van-button @click="confirmCancelContract" loading-type="spinner" :loading="confirmLoading"
                        :class=" cancellationOfContract.reasonForCancellationStr ? 'saveButton_Enable' : 'saveButton_Disable' "
                        :disabled="!cancellationOfContract.reasonForCancellationStr"
            >确定
            </van-button>
        </van-popup>
    </div>
</template>

<script>
import {NavBar, Picker, Popup, PullRefresh, Overlay, Button,} from 'vant';
    import Vue from 'vue';
    import { List ,Toast,Dialog} from 'vant';
    Vue.use(List);
    import {
        contractList,
        queryBaseData,
        ownerContractSign,
        contractInvalid,
        getStaffFuntionModelList,
        delDecoration,renterContractSign,userGetPassword,compulsoryDissolution,checkFangxinAuthentication,
        renterContractBilldelete,cancellationOfLeaseTerm,
    } from "../../../getData/getData";
    import {responseUtil, checkAuthList, getStaffId, globaluserId} from "../../../libs/rongxunUtil";
    import rxNavBar from "../../../components/rongxun/rx-navBar/navBar";
    import dialMobile from '../../../components/rongxun/rx-dialMobile/dialMobile'
    export default {
        name: "contractNew",
        components:{
            [NavBar.name]:NavBar,
            [Toast.name]:Toast,
            [Dialog.name]:Dialog,
            [Picker.name]: Picker,
            [Popup.name]:Popup ,
            rxNavBar,
            dialMobile,
            [PullRefresh.name]:PullRefresh,
            [Overlay .name]: Overlay ,
            [Button.name]: Button,
        },
        data(){
            return{
                doorPassWord:'',
                districtPassWord:'',
                pwdShow:false,
                fromType:'',  // 入口标识，0 为房源相关页面
                setHouse_id: '', // 房源相关页面进入，根据setHouse_id查询相关历史业主合同
                roomHouse_id:'',  // 房源相关页面进入，根据roomHouse_id查询相关历史租客合同
                isChooseLeaseChange:'0',//用户是否点击过租约变更查询租约变更 0代表没有点击，1代表点击了
                noContent:false,
                currentLabel:'1',//工作台会传值，如果没传值就默认初始化全部
                searchName:'',
                count:'0',
                contractStatus:'',
                typeList:[//合同全部类型
                    {dictionaryTitle:'全部'}, {dictionaryTitle:'待签字'},
                    {dictionaryTitle:'租期中'}, {dictionaryTitle:'已解除'},
                    {dictionaryTitle:'已作废'},{dictionaryTitle:'待交割'},
                    {dictionaryTitle:'未交房'},{dictionaryTitle:'预到期'},{dictionaryTitle:'驳回'}
                ],
                typeShow:'全部',
                type:'1',//1代表租客,2代表业主
                page:1,
                loading: false,
                finished: false,
                immediateCheck:false,
                refreshing: false,
                contractType:'1',
                isShow:false,//显示与影藏下拉条件选择菜单
                number:0,//记录当前选择的是哪一种类型的合同
                clickType:'全部',//页面初始化默认的合同类型
                contractList:[],//合同列表
                //待签字合同列表
                toBeSignedList:[],
                //待交割合同列表
                toBeDeliveryList:[],
                //租期中合同列表
                onLeaseList:[],
                //已解除合同列表
                relieveList:[],
                //拨打电话弹窗
                isMobileShow:false,
                temporaryOwnerMobile:'',
                temporaryOwnerName:'',
                dialogTitle:'联系业主',
                authOwnerButtonsList:[],//业主合同按钮权限列表
                authRenterButtonsList:[],//租客合同按钮权限列表
                cancelLimits:false,
                isShowdetailInfo:false,
                cancellationOfContractShow:false,
              cancellationOfContract:{
                cancelReason:'',
                nullifyReason:'',
              },
              isCancelReasonSelect:false,
              cancelReasonList:[],
              cancelReasonIndex:0,
              confirmLoading:false,
              matainStaff_id:'',
            }
        },
        created() {
            // 入口标识（fromType 0  房源相关页面进入）
            if(this.$route.query.fromType){
              this.fromType = this.$route.query.fromType
            }
            // 间房源id
            if(this.$route.query.room_id){
                this.roomHouse_id = this.$route.query.room_id
            }
          // 间房源id

          if(this.$route.query.setHouse_id){
            this.setHouse_id = this.$route.query.setHouse_id
          }

            // 合同状态
            if(this.$route.query.currentLabel){
                //先判断有无缓存,无缓存再set操作
                if(!localStorage.getItem('currentLabel')){
                    localStorage.setItem('currentLabel',JSON.stringify(this.$route.query.currentLabel))
                }
            }

            //
            if(localStorage.getItem('currentLabel')){
                let currentLabel = JSON.parse(localStorage.getItem('currentLabel'))
                if(undefined !== currentLabel && '' !== currentLabel){
                    this.number = Number(currentLabel)
                }
            }
            if(this.$route.query.fromType == '0'){
              this.type = Number(this.$route.query.type)
            }else{
              if(localStorage.getItem('type')){
                let type = JSON.parse(localStorage.getItem('type'))
                if(undefined !== type && '' !== type){
                  this.type = Number(type)
                }
              }
              // 合同类型 业主 or 租客
              //debugger
              if(this.$route.query.type){
                //先判断有无缓存,无缓存再set操作
                if(!localStorage.getItem('type')){
                  localStorage.setItem('type',JSON.stringify(this.$route.query.type))
                  if(this.$route.query.type == '1') {
                    this.type == '1'
                    this.showTenant()
                  }
                  if(this.$route.query.type == '2') {
                    this.type == '2'
                    this.showOwner()

                  }
                }
              }
            }
            this.matainStaff_id = getStaffId()
            // if(this.$route.query.type)
            //   this.type = this.$route.query.type
            // if(this.$route.query.currentLabel)
            //   this.currentLabel = this.$route.query.currentLabel
        },

        mounted() {
            // this.number = 7
            this.getOwnerStaffFuntionModelList()
            this.getRenterStaffFuntionModelList()
            // this.changeType('',this.number)
        },

        // beforeRouteLeave(to, from, next){
        //     if(to.name == 'contractDetail'){
        //         from.meta.keepAlive = true;
        //     }else {
        //         from.meta.keepAlive = false;
        //     }
        //     next()
        // },

        beforeRouteEnter(to, from, next) {
            //清除本地localStorage缓存
            if(from.name == null){
                localStorage.removeItem('currentLabel');
                localStorage.removeItem('type');
                localStorage.removeItem('inputValue');
                localStorage.removeItem('currentLabelPublic');
            }
            if(from.name == 'contractDetail'){
                to.meta.keepAlive = true;
            }else {
                to.meta.keepAlive = false;
            }
            next()
        },
        methods:{
            //取消
            goCancel(item){
      Dialog.confirm({
        title: '友情提示',
        message: '确定取消租约变更吗？',
      })
        .then(() => {
          // on confirm
          let that = this
                        let queryData = {};
                        queryData.contractHistoryId = item.contracthistoryId;
                        queryData.status = '3';
                        queryData.user_id = getStaffId()//globaluserId();
                        queryData.contractHistoryHistoryType = item.contractHistoryHistoryType
                        queryData.contractType = this.type==2?'1':'0'
                        compulsoryDissolution(queryData).then(function (response) {
                            responseUtil.dealResponse(that, response, () => {
                                that.finished = false;
                                that.contractList = []
                                that.page = 1
                                that.loading = true;
                                that.refreshing = false;                                
                                that.onLoad()
                            })
                        })
        })
        .catch(() => {
          // on cancel
        })              
            },
            //获取密码
            getPassword(e){
                var that=this
                var initData={}
                initData.user_id = globaluserId()
                initData.setHouse_id = e.setHouse_id
                initData.roomHouse_id = e.roomHouse_id
                //initData.setHouse_id =10
                //initData.roomHouse_id=283
                initData.type = "6"             
                userGetPassword(initData).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        that.doorPassWord = response.data.data.password
                        that.districtPassWord = response.data.data.privatePwd
                        
                    })
                    that.pwdShow = true
                })                
            },
            onRefresh() {
                // 清空列表数据
                this.finished = false;
                this.contractList = []
                this.page = 1
                // 重新加载数据
                // 将 loading 设置为 true，表示处于加载状态
                this.loading = true;
                this.refreshing = false;
                this.onLoad();
                responseUtil.alertMsg(this,"刷新成功")
            },

            //获取业主合同权限方法
            getOwnerStaffFuntionModelList () {
                var that = this
                let data = {}
                data.staff_id = getStaffId()
                data.menuName = 'ownerContractList_index'
                getStaffFuntionModelList(data).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        that.authOwnerButtonsList = response.data.data.data
                    })
                })
            },

            //获取租客合同权限方法
            getRenterStaffFuntionModelList () {
                var that = this
                let data = {}
                data.staff_id = getStaffId()
                data.menuName = 'renterContract_index'
                getStaffFuntionModelList(data).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        that.authRenterButtonsList = response.data.data.data
                        for(var i in that.authRenterButtonsList){
                           if('cancel'==that.authRenterButtonsList[i]){
                               that.cancelLimits=true
                               break
                           }
                          if('findPhone'==that.authRenterButtonsList[i]){
                            that.isShowdetailInfo=true
                            break
                          }
                        }
                    })
                })
            },

            checkAuthList,
            //返回上一层
            leftReturn(){
                this.$router.go(-1);
            },

            //选择租约变更
            chooseOnLease(){
                this.isChooseLeaseChange = '1'

                this.page=1
                this.contractList=[]
                this.loading = false
                this.finished = false
                this.currentLabel='2'
                this.loading = true
                this.noContent = false
                this.onLoad()
            },

            checkCanLease(){
                let that = this
                if(that.type==1){
                    if(checkAuthList(that.authRenterButtonsList,'terminate') || checkAuthList(that.authRenterButtonsList,'renewal')
                        || checkAuthList(that.authRenterButtonsList,'toSublet') || checkAuthList(that.authRenterButtonsList,'exchangeHouses')){
                        return true
                    }
                    return false
                } else {
                    if(checkAuthList(that.authOwnerButtonsList,'terminate') || checkAuthList(that.authOwnerButtonsList,'renewal')){
                        return true
                    }
                    return false
                }
            },
            //人脸识别
            checkFangxinAuthentication(item){
                let that = this
                let initData = {}
                initData.userId = item.userId
                checkFangxinAuthentication(initData).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        if(response.data.code==0){
                          // console.log(response.data.data.data.h5Url)
                          that.$router.push({
                              name:'checkFangxinAuthentication',
                            query:{
                              path:response.data.data.data.h5Url //https://mobile.fangxinqian.cn/login?rid=C33A359A89FA658FD08E2957F8676FD7&ut=1&way=2
                            }
                          })
                          // window.location.href = response.data.data.data.h5Url

                          // Uri uri = Uri.parse("http://google.com");
                          // Intent it = new Intent(Intent.ACTION_VIEW, uri);
                          // startActivity(it);
                        }
                    })
                })
            },
            //签字
            signature(item){
                Dialog.confirm({
                    title: '友情提示',
                    message: '确认要发起签字么？',
                }).then(() => {
                    let that = this
                    let initData = {}
                    initData.staff_id = getStaffId()
                    //调用签约接口
                    if(that.type==1){//租客签字
                        initData.rentercontract_id = item.rentercontract_id
                        renterContractSign(initData).then(function (response) {
                            responseUtil.dealResponse(that, response, () => {
                                responseUtil.alertMsg(that,'签字发起成功')
                                that.changeType('',that.number)
                            })
                        })
                    } else {
                        initData.ownerContractId = item.ownerContractId
                        ownerContractSign(initData).then(function (response) {
                            responseUtil.dealResponse(that, response, () => {
                                responseUtil.alertMsg(that,'签字发起成功')
                                that.changeType('',that.number)
                            })
                        })
                    }
                }).catch(() => {

                });
            },

            //修改合同
            changeContract(item){
                if(this.type==1){
                    //修改租客合同
                    this.$router.push({
                        name : 'NewContract',
                        query : {
                            contractId: item.rentercontract_id,
                            flagType:'1',
                            roomId:item.roomHouse_id,     //赵
                            alertType:'修改',
                            isBD:item.isBD?'1':'0',
                            staffName:item.BDStaff,
                            contractHistoryHistoryTypeVal:item.contractHistoryHistoryTypeVal,
                            contractStatus:item.contractStatus,
                            approvalStatus:item.approvalStatus,
                        }
                    })
                } else {
                    //修改业主合同
                    this.$router.push({
                        name : 'newOwnerContract',
                        params : {
                            ownerContractId: item.ownerContractId,
                            flagType:'1'
                        }
                    })
                }
            },

            //作废合同
            invalidContract(item){
                Dialog.confirm({
                    title: '友情提示',
                    message: '确认要作废该合同么？',
                }).then(() => {
                    //调用作废接口
                    let that = this
                    let initData = {}
                    initData.contractId = item.contractId
                    contractInvalid(initData).then(function (response) {
                        responseUtil.dealResponse(that, response, () => {
                            if(response.data.code=='0'){
                                responseUtil.alertMsg(that,'作废成功')
                                that.changeType('',0)
                            } else {
                                responseUtil.alertMsg(that,'作废失败')
                            }
                        })
                    })
                }).catch(() => {

                });
            },

            // 管理作废
            whetherReject(item){
              let that=this
              that.rowData=item
              let initData={}
              initData.contractId=item.contractId
              renterContractBilldelete(initData).then(function (response) {
                responseUtil.dealResponse(that, response, () => {
                  let info=response.data.data.data
                  if(info.length>0&&info[0].sum>0){
                    //不可驳回
                    responseUtil.alertMsg(that, "存在已收或已支数据，不可驳回")
                    return
                  }else{
                    that.confirmCancel(item)
                  }
                })
              })
            },
            confirmCancel(item){
              this.cancellationOfContract={
                cancelReason:'',
                nullifyReason:'',
              },
              this.cancellationOfContractShow = true
              this.initChooseTypes()
            },
          clickCancelReasonShow(){
              this.isCancelReasonSelect = true
          },
          selectCancelReasonOption(item,index){
            if(item){
              this.cancellationOfContract.reasonForCancellation = item.id
              this.cancellationOfContract.reasonForCancellationStr = item.dictionaryTitle
              this.cancelReasonIndex = index
            }
            this.isCancelReasonSelect = false
          },
          confirmCancelContract(){
            let that = this
            let initData={}
            initData.cid=that.rowData.contractId
            initData.roomHouseId=that.rowData.roomHouse_id
            initData.addStaffId=getStaffId()
            initData.reasonForCancellation=that.cancellationOfContract.reasonForCancellation   //作废原因id
            initData.nullifyReason=that.cancellationOfContract.nullifyReason  //remark
            this.confirmLoading = true
            cancellationOfLeaseTerm(initData).then(function (response) {
              that.confirmLoading = false
              responseUtil.dealResponse(that, response, () => {
                responseUtil.alertOK(that)
                that.cancellationOfContract={nullifyReason:'',reasonForCancellation:''}
                that.cancellationOfContractShow = false
                that.finished = false;
                that.contractList = []
                that.page = 1
                that.loading = true;
                that.refreshing = false;
                that.onLoad()
              })
            })
          },

            //确认物业交割
            confirm(item){
                let type = '0'//0代表添加，1代表修改
                let propertyType = '1'//默认由合同添加物业交割单,如果传入值为2表示由租约变更添加物业交割单
                if(item.contractPropertyId){
                    type = '1'
                }
                if(this.isChooseLeaseChange == '1'){//表明租约变更了
                    propertyType = '2'
                }
                this.$router.push({
                    name : 'addPropertyBill',
                    query : {
                        roomHouse_id: item.roomHouse_id,
                        setHouse_id: item.setHouse_id,
                        contractId: item.contractId,
                        contractPropertyId: item.contractPropertyId,
                        type:type,
                        propertyType:propertyType,
                        contracthistoryId:item.contracthistoryId || '',
                        mold:this.type,
                        houseType:item.houseType,   //房源类型
                        flag:"ruzhu"
                    }
                })
            },
            confirmT(item){
                let type = '0'//0代表添加，1代表修改
                let propertyType = '1'//默认由合同添加物业交割单,如果传入值为2表示由租约变更添加物业交割单
                if(item.contractPropertyId){
                    type = '1'
                }
                if(this.isChooseLeaseChange == '1'){//表明租约变更了
                    propertyType = '2'
                }
                this.$router.push({
                    name : 'addPropertyBill',
                    query : {
                        roomHouse_id: item.roomHouse_id,
                        setHouse_id: item.setHouse_id,
                        contractId: item.contractId,
                        contractPropertyId: item.contractPropertyId,
                        type:type,
                        propertyType:propertyType,
                        contracthistoryId:item.contracthistoryId || '',
                        mold:this.type,
                        houseType:item.houseType,   //房源类型
                        flag:"jiechu"
                    }
                })
            },

            //租约变更
            changeLease(item){
                //跳转到租约变更页面
                let id ,ownerOrRenterContractId,mobile,contractId
                if(this.type=='1'){//租客
                    id = item.roomHouse_id
                    ownerOrRenterContractId = item.rentercontract_id
                    mobile = item.renterMobile
                    contractId = item.contractId
                } else {//业主
                    id = item.setHouse_id

                    ownerOrRenterContractId = item.ownerContractId
                    mobile = item.ownerMobile
                    contractId = item.contractId
                }
                this.$router.push({
                    name : 'changeLease',
                    query : {
                        id: id,
                        type:this.type,
                        mode:'1',
                        ownerOrRenterContractId:ownerOrRenterContractId,
                        mobile:mobile,
                        houseAddress:item.houseAddress,
                        contractId:contractId,
                        estateRoom_id:item.estateRoom_id,
                        contractCode:item.contractCode,
                        potentialResource_id:item.potentialResource_id,
                        setHouse_id:item.setHouse_id,
                        matainStaff_id:item.matainStaff_id,  //维护人
                    }
                })
            },

            //拨打电话
            relationTo(item){
                if(this.type=='2'){
                    this.temporaryOwnerMobile = item.ownerMobile
                    this.temporaryOwnerName = item.ownerName
                    this.isMobileShow = !this.isMobileShow
                    this.dialogTitle = '联系业主'
                } else {
                    this.temporaryOwnerMobile = item.renterMobile
                    this.temporaryOwnerName = item.renterName
                    this.isMobileShow = !this.isMobileShow
                    this.dialogTitle = '联系租客'
                }
            },
            //关闭拨打电话弹窗
            hideDialog() {
                let that = this;
                that.isMobileShow = false;
            },

            //合同详情
            detailsClick(index){
                if((this.type=='1'&&checkAuthList(this.authRenterButtonsList,'showDetail')) ||(this.type=='2'&&checkAuthList(this.authOwnerButtonsList,'showDetail'))){
                    let ownerOrRenterContractId
                    if(this.type=='1'){
                        ownerOrRenterContractId = this.contractList[index].rentercontract_id
                    } else {
                        ownerOrRenterContractId = this.contractList[index].ownerContractId
                    }
                    this.$router.push({
                        name:'contractDetail',
                        query:{
                            contractId:this.contractList[index].contractId,
                            contractHistoryId:this.contractList[index].contracthistoryId ||'',
                            type:this.type,
                            ownerOrRenterContractId:ownerOrRenterContractId,
                            historyType:this.contractList[index].contractHistoryHistoryTypeVal,
                            contractTypeVal:this.contractList[index].contractStatusVal,
                            contractStatus:this.contractList[index].contractStatus,
                            mode:'1',
                            isShowdetailInfo:this.isShowdetailInfo,
                            houseType:this.contractList[index].houseType
                        }
                    });
                }
            },
            //搜索按钮
            searchSubmit(){
                this.isChooseLeaseChange = '0'
                this.changeType('',this.number)
            },
            //初始化合同状态
            initChooseTypes:function(){
                let that = this
                let initData = {}
                initData.dbName = ['description','reasonCancellation']
                initData.fdName = ['CONTRACTSTATUSMAP']
                queryBaseData(initData).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        // that.typeList=response.data.data.CONTRACTSTATUSMAP
                      that.cancelReasonList=response.data.data.reasonCancellation
                        //that.typeShow=that.typeList[0].dictionaryTitle

                    })
                })
            },
            showOrHidden: function(){
                this.isShow = !this.isShow;
                var sp = document.getElementById("downList");
                if(sp){
                    document.addEventListener('click',e =>{
                        if(!sp.contains(e.target)){
                            this.isShow = false;
                        }
                    })
                }
            },
            //点击合同类型触发的筛选事件
            changeType:function(value,index){
                this.isChooseLeaseChange = '0'
                this.page=1
                this.contractList=[]
                this.loading = false
                this.finished = false
                this.isShow=false
                this.number=index
                //合同状态
                localStorage.setItem('currentLabel',JSON.stringify(this.number))
                //合同类型
                localStorage.setItem('type',JSON.stringify(this.type))
                this.loading = true
                this.noContent = false
                this.onLoad()
            },
            onLoad:function(){
                this.contractListInit()
            },
            contractListInit(){
                let that = this
                let contractListData={}
                contractListData.type=that.type
                switch (parseInt(that.number)) {
                    case 0:
                        //全部类型
                        that.currentLabel='1'
                        break;
                    case 1:
                        //代签字
                        that.currentLabel='5'
                        break;
                    case 2:
                        //租期中
                        that.currentLabel='2'
                        break;
                    case 3:
                        //已解除
                        that.currentLabel='3'
                        break;
                    case 4:
                        //已作废
                        that.currentLabel='4'
                        break;
                    case 5:
                        //待交割
                        that.currentLabel='6'
                        break;
                    case 6:
                        //未交房
                        that.currentLabel='7'
                        break;
                    case 7:
                        //预到期
                        that.currentLabel='8'
                        break;
                    case 8:
                        //驳回
                        that.currentLabel='9'
                        break;
                }
                contractListData.currentPage=that.page+''
                contractListData.numberPage='3'
                contractListData.contractStatus=''
                contractListData.roleType=''
                contractListData.userId=getStaffId()
                contractListData.globaluserId=''
                //contractListData.globaluserId=globaluserId()
                contractListData.dicId=''
                contractListData.keywords=''
                contractListData.currentLabel=that.currentLabel
                contractListData.searchName=that.searchName
                contractListData.isChooseLeaseChange=that.isChooseLeaseChange
                contractListData.roomHouse_id=that.roomHouse_id  // 房源相关页面进入，根据roomhouse_id查询相关历史租客合同
                contractListData.setHouse_id=that.setHouse_id  // 房源相关页面进入，根据setHouse_id查询相关历史租客合同
                //that.chooseType(that.currentLabel)
                contractList(contractListData).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        var count = response.data.data.pageCount
                        let list = response.data.data.data
                        if (list){
                            for(let i=0;i<list.length;i++){
                                that.contractList.push(list[i])
                            }
                        }
                        that.loading = false;
                        if(that.refreshing){
                            that.refreshing = false
                        }
                        if (that.contractList.length >=count) {
                            that.finished = true;
                        }
                        if(1==that.page&&!list){
                            that.noContent = true
                        }else{
                            that.noContent = false
                        }
                        that.page++;
                    })
                })

            },
            //切换租约合同
            showTenant(){
                let that=this
                that.contractList=[]
                that.type='1'
                that.changeType('',that.number)
            },
            //切换业主合同
            showOwner(){
                let that=this
                that.contractList=[]
                that.type='2'
                that.changeType('',that.number)
            },

            //判断当前属于哪种状态
            chooseType(item){
                if(item=='1'){
                    this.number=0
                }
                if(item=='5'){
                    this.number=1
                }
                if(item=='2'){
                    this.number=2
                }
                if(item=='3'){
                    this.number=3
                }
                if(item=='4'){
                    this.number=4
                }
                if(item=='6'){
                    this.number=5
                }
                if(item=='7'){
                    this.number=6
                }
                if(item=='8'){
                    this.number=7
                }
                 if(item=='9'){
                    this.number=8
                }
                //this.typeShow = this.typeList[this.number].dictionaryTitle
            },
            //关闭密码弹窗
            closeMobileModel(){
                this.pwdShow = false
            },            
        }
    }
</script>

<style scoped>
    .nav-top /deep/ .van-nav-bar{
        background-color: white;
        border-bottom: 2px solid white;
    }
    .outside{
        overflow: hidden;
        width: 100%;
    }
    .small-icon{
        width: 8px;
        height: 8px;
        margin-left: 5px;
        background-image: url("../../../assets/images/small-icon.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100%;
    }
    .small-icon-rotate{
        width: 8px;
        height: 8px;
        margin-left: 5px;
        margin-bottom: 5px;
        background-image: url("../../../assets/images/small-icon.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100%;
        transform:rotate(180deg);
    }
    .select{
        width: 100%;
        height: 35px;
        line-height: 30px;
        font-size: 14px;
        text-align: left;
        margin-left: 15px;
        color: #999999;
    }
    .selectActive{
        color: #fe5e3a;
    }
    .selectList{
        width: 100%;
        padding: 5px 0 14px 0;
        background-color: white;
    }
    .navbar{
        background-color: white;
        border-bottom: 2px solid white;
    }
    .header{
        width: 100%;
        height: auto;
        position: fixed;
        z-index: 8;
        top: 50px;
        background-color: #f8f8f8;
    }
    .choose{
        width: 100%;
        height: 56px;
        background-color: white;
        display: flex;
    }
    .choose-one{
        width: 187px;
        text-align: center;
    }
    .choose-one-textActive{
        font-size: 14px;
        color: #fe5e3a;
        font-weight: bold;
        margin-top: 10px;
    }
    .choose-one-text{
        font-size: 14px;
        color: #999999;
        margin-top: 10px;
    }
    .choose-one-icon{
        width: 20px;
        height: 4px;
        border-radius: 3px;
        background-color: #fe5e3a;
        margin: 10px 0 0 84px;
    }
    .choose-line{
        width: 1px;
        height: 17px;
        margin-top: 13px;
        background-color: #e0e0e0;
    }
    .search{
        margin: 15px 20px 0 20px;
        height: 35px;
        border-radius: 30px;
        background-color: #f4f4f4;
        display: flex;
        align-items: center;
    }
    .search-icon{
        width: 25px;
        height: 25px;
        margin-left: 10px;
        background-image: url("../../../assets/images/search-icon.png");
        background-size: 100%;
        background-position: center;
        background-repeat: no-repeat;
    }
    input{
        font-size: 14px;
        margin-left: 15px;
        margin-top: 3px;
        background-color: #f4f4f4;
        border: 0;
        width: 250px;
    }
    input::-webkit-input-placeholder{
        color: #d9d9d9;
    }
    .types{
        width: 100%;
        height: 45px;
        display: flex;
        align-items: center;
    }
    .type{
        font-size: 14px;
        color: #fe5e3a;
        margin-left: 15px;
    }
    .typeActive{
        color: #fe5e3a;
        font-weight: bold;
    }
    .type-line{
        width: 1px;
        height: 12px;
        margin-top: -2px;
        background-color: #e0e0e0;
    }
    .leaseChange{
        font-size: 14px;
        color: black;
        margin-left: 15px;
    }
    .leaseChangeActive{
        font-size: 14px;
        color: #fe5e3a;
        margin-left: 15px;
    }
    .contracts{
        width: 100%;
        overflow: hidden;
        position: relative;
    }
    .contract{
        position: relative;
        margin: 0 15px 15px 15px;
        height: auto;
        background-color: white;
        border-radius: 8px;
        overflow: hidden;
    }
    .top-title-orange{
        margin: 0;
        width: 45px;
        height: 20px;
        font-size: 10px;
        text-align: center;
        line-height: 22px;
        border-radius: 8px 0;
        background: linear-gradient(to right,#ffbe72,#ff6c41);
        color: white;
    }
    .top-title-green{
        margin: 0;
        width: 45px;
        height: 20px;
        font-size: 10px;
        text-align: center;
        line-height: 22px;
        border-radius: 8px 0;
        background: linear-gradient(to right,#63cb96,#30d0ac);
        color: white;
    }
    .top-title-red{
        margin: 0;
        width: 45px;
        height: 20px;
        font-size: 10px;
        text-align: center;
        line-height: 22px;
        border-radius: 8px 0;
        background: linear-gradient(to right,#fc401b,#e02321);
        color: white;
    }
    .position{
        margin: 15px 50px 0 15px;
        height: auto;
        text-align: left;
        font-size: 16px;
        font-weight: bold;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
    .tel-icon{
        width: 25px;
        height: 25px;
        position: absolute;
        top: 32px;
        right: 20px;
        background-image: url("../../../assets/images/relation-phone.png");
        background-size: 100%;
        background-position: center;
        background-repeat: no-repeat;
    }
    .details{
        margin-top: 15px;
        width: 100%;
        height: auto;
        border-bottom: 1px solid #e0e0e0;
        display: flex;
    }
    .details .info{
        width: 85.5px;
        height: auto;
        text-align: center;
    }
    .details .info .order{
        width: 100%;
        height: 20px;
        font-size: 14px;
        line-height: 20px;
        color: #999999;
    }
    .details .info .order-name{
        width: 100%;
        height: 20px;
        font-size: 14px;
        line-height: 20px;
        color: #fe5e3a;
        margin-top: 10px;
        margin-bottom: 15px;
        white-space: nowrap;
        text-overflow:ellipsis;
        overflow: hidden;
        font-weight: bold;
    }
    .details .line{
        width: 1px;
        height: 40px;
        background-color: #e0e0e0;
        margin-top: 8px;
    }
    .contract-info{
        width: 100%;
        height: auto;
        padding: 15px 0 10px 0;
        border-bottom: 1px solid #e0e0e0;
    }
    .contract-text{
        height: 25px;
        line-height: 25px;
        text-align: left;
        margin-left: 20px;
        color: #999999;
        font-size: 14px;
    }
    .bottom{
        width: 330px;
        height: 60px;
        padding-right: 15px;
        overflow: hidden;
    }
    .bottom-sign{
        float: right;
        width: 80px;
        height: 30px;
        margin-top: 15px;
        margin-left: 11px;
        line-height: 30px;
        background: linear-gradient(to right,#ffbe72,#ff6c41);
        color: white;
        font-size: 12px;
        text-align: center;
        border-radius: 8px;
    }
    .bottom-change{
        float: right;
        width: 80px;
        height: 30px;
        margin-top: 15px;
        margin-left: 11px;
        line-height: 30px;
        background: linear-gradient(to right,#7cc7ff,#3596fd);
        color: white;
        font-size: 12px;
        text-align: center;
        border-radius: 8px;
    }
    .bottom-invalid{
        float: right;
        width: 80px;
        height: 30px;
        margin-top: 15px;
        margin-left: 11px;
        line-height: 30px;
        background: linear-gradient(to right,#E02020,#FF431B);
        color: white;
        font-size: 12px;
        text-align: center;
        border-radius: 8px;
    }
    .bottom-property{
        float: right;
        width: 90px;
        height: 30px;
        margin-top: 15px;
        margin-left: 11px;
        right: 15px;
        line-height: 30px;
        background: linear-gradient(to right,#ffbe72,#ff6c41);
        color: white;
        font-size: 12px;
        text-align: center;
        border-radius: 8px;
    }
    .bottom-changeLease{
        float: right;
        width: 80px;
        height: 30px;
        margin-top: 15px;
        margin-left: 11px;
        line-height: 30px;
        background: linear-gradient(to right,#7cc7ff,#3596fd);
        color: white;
        font-size: 12px;
        text-align: center;
        border-radius: 8px;
    }
    .blankWeight{
        height: 151px;
    }
    .wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
    }
    .block {
        width: 76%;
        height: 21.5%;
        background-color: #fff;
        border-radius: 8px;
        overflow: hidden;
    }
    /*    获取密码DIV*/
    .getPassword{
        background: url("../../../assets/images/ContactPerson-img.png") no-repeat center center,
        linear-gradient(to right,#FFC274 ,#FF5D3B);
        text-align: center;
        height: 50px;
        line-height: 53px;
        color: white;
        background-size: 100%;
        font-size: 16px;
    }
    /*    门锁密码*/
    .LockPassWord{
        margin-top: 20px;
        text-align: center;
        font-size: 16px;
        font-weight: bold;
        line-height: 14px;
    }

    /*传入的密码值*/
    .password{
        color: #ff8247;
        font-size: 20px;
        margin-top: 40px;
        font-family: SimSun;
    }
    /*关闭按钮*/
    .closeImg{
        margin-top: 100px;
        height: 30px;
        width: 30px;
        border: 1px solid white;
        border-radius: 50%;
        background: url("../../../assets/images/GetDoorPassword-closeImg.png") no-repeat center center;
        background-size:18px 18px;
    } 
        .van-overlay{
            background-color: rgba(80,80,80,.7);;
        }    
    .bottom_iconDiv{
        float: left;
        width: 120px;
        height: 30px;
    } 
    .pwd_IMG{
        float: left;
        width: 30px;
        height: 25px;
        margin-left: 15px;
        margin-top: 3px;
    }
    .icon{
        float: left;
        width: 120px;
        height: 30px;
        margin-top: 10px;
    } 
    .getPwd{
        font-size: 14px;
        float: left;
        color: #9a9a9a;
        margin-top: 6px;
    }
    /*弹窗样式*/
    .van-popup--bottom {
      /*z-index: 2004;*/
      background-color: #f8f8f8;
      font-size: 0.3rem;
    }
    .cancel-img {
      margin: 30px 5px -2px 15px;
      width: 18px;
      height: 16px;
    }
    .cancel-textarea {
      margin: 15px 15px 0;
      width: 325px;
      padding: 10px;
      height: 80px;
      border-radius: 8px;
      border: none;
      background-color: #FaFaFa;
      resize: none;
    }

    .cancel-textarea::-webkit-input-placeholder {
      color: #d8d8d8;
    }
    .weight {
      font-weight: 900;
      font-size: 0.3rem;
    }
    .part-inputpart {
      display: flex;
      flex-direction: column;
      white-space: nowrap;
      overflow: hidden;
      font-size: 15px;
    }

    /*选择名称的div*/
    .part-inputpart-row {
      display: flex;
      flex-direction: row;
      border-radius: 8px;
      align-items: center;
      height: 45px;
      background-color: #ffffff;
      margin: 15px 15px 0 15px;
    }

    .part-inputpart-row-header {
      font-weight: bold;
      font-size: 14px;
      width: 150px;
    }

    /*竖线样式*/
    .content-divide {
      font-size: 14px;
      color: #efefef;
      margin: 0 5px 0 5px;
    }

    .part-inputpart-row-graytext {
      color: #d8d8d8;
      width: 100%;
      font-size: 14px;
    }

    .part-inputpart-row-right {
      float: right;
      margin-right: 10px;
      font-size: 14px;
      color: #D8D8D8;
    }

    .part-inputpart-dropDownList {
      height: 23%;
      /*垂直方向滚动*/
      /*overflow-y: scroll;*/
      overflow: auto;
      width: 92.5%;
      position: absolute;
      z-index: 99;
    }

    .part-inputpart-row-normaltext {
      color: black;
    }

    /*选择三角图标的向上向下样式*/
    .part-inputpart-row-right-upArrow {
      width: 9px;
      transform: rotateX(180deg);
    }

    .part-inputpart-row-right-downArrow {
      width: 9px;
    }

    .part-inputpart-dropDownList {
      height: 23%;
      /*垂直方向滚动*/
      /*overflow-y: scroll;*/
      overflow: auto;
      width: 100%;
      position: absolute;
      z-index: 99;
    }

    .part-inputpart-dropDownList-option {
      padding: 0;
      margin-left: 15px;
      width: 345px;
      text-align: center;
      line-height: 30px;
      background-color: #f5f5f5;
      font-size: 12px;
      border-bottom: 1px white solid;
      color: #999999;
    }
    .content-have {
      padding: 3px;
      margin: 0 10px;
      border-radius: 50%;
      background-color: #ff5d3b;
    }

    .content-none {
      padding: 3px;
      margin: 0 10px;
      border-radius: 50%;
      background-color: #c7c7c7;
    }
    /*保存按钮可点击样式*/
    ::v-deep .saveButton_Enable {
      background: linear-gradient(to right, #fdc07f 0px, #f86513 100%) repeat scroll 0% 0%;
      color: white;
      width: 345px;
      height: 50px;
      border-radius: 8px;
      margin: 25px 15px;
      font-size: 0.5rem;
      line-height: 1.22rem;
      text-align: center;
    }
    /*保存按钮不可点击样式*/
    ::v-deep .saveButton_Disable{
      /*background: linear-gradient(to right, rgba(184, 184, 184, 0.2), #B8B8B8 20%) repeat scroll 0% 0%;*/
      background-color: rgba(184, 184, 184, 0.2);
      color: white;
      width: 345px;
      height: 50px;
      border-radius: 8px;
      margin: 25px 15px;
      font-size: 18px;
      line-height: 1.22rem;
      text-align: center;
    }

</style>
